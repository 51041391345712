import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PlanConfigurationSection } from '../../services/constants';
import { PlanService } from '../../services/plan/plan.service';

@Injectable()
export class MemberConfigurationResolver {
  constructor(private planService: PlanService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.planService.getPlanSection(
      route.params['id'],
      PlanConfigurationSection.Member,
    );
  }
}
